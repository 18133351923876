.appointmentFormContainer {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  margin: 0 auto;
}

.backToTopBtn {
  position: fixed;
  width: 5rem;
  height: 5rem;
  background-color: gray;
  border: none;
  font-size: 3rem;
  color: white;
  bottom: 5rem;
  right: 2rem;
  border-radius: 50%;
  cursor: pointer;
  transition: visibility 0s, opacity 0.5s linear;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
}

.container {
  position: relative;
}

.showBackToTopBtn {
  visibility: visible;
  opacity: 0.6;
}

@media only screen and (max-width: 850px) {
  .appointmentFormContainer {
    flex-direction: column;
  }
}
