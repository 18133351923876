.desktopMenu {
  flex-grow: 0.8;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 9rem;
  width: 100%;
  user-select: none;
  background-color: white;
  /* margin-top: 1rem; */
  box-shadow: 0 1px 0 rgb(0 0 0 / 10%);
}

.logo a {
  display: flex;
  align-items: center;
}

.logo img {
  height: 7rem;
}

.menuIcon {
  display: none;
  cursor: pointer;
}

.mobileMenu {
  display: none;
}

.navBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: var(--max-width);
}

@media only screen and (max-width: 1920px) {
  .header {
    padding: 0 2rem;
  }
}

@media only screen and (max-width: 1024px) {
  .header {
    height: 9rem;
  }

  .logo img {
    height: 6rem;
  }
}

@media only screen and (max-width: 950px) {
  .desktopMenu {
    display: none;
  }

  .logo img {
    height: 5rem;
  }

  .menuIcon {
    display: flex;
    align-items: center;
    font-size: 3rem;
    padding: 0 2rem;
    color: var(--dark-blue);
  }

  .mobileMenu {
    display: block;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .show {
    height: 25rem;
  }
}

@media only screen and (max-width: 450px) {
  .logo img {
    width: 100%;
    height: auto;
  }
}
