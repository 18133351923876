.activeNavLink {
  color: var(--dark-blue);
  font-weight: 700;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0.5;
  font-size: 1.6rem;
}

.navLink {
  color: gray;
}

@media only screen and (max-width: 1024px) {
  .button {
    font-size: 1.2rem;
  }

  .container {
    flex-grow: 1;
    padding: 0 0 0 5rem;
  }
}

@media only screen and (max-width: 950px) {
  .activeNavLink,
  .navLink {
    background-color: var(--dark-blue);
    color: white;
  }

  .container {
    padding: 0;
    flex-direction: column;
  }

  .container li {
    width: 100%;
  }

  .container li a {
    display: flex;
    align-items: center;
    padding: 0 2rem;
    width: 100%;
    height: 6rem;
    border-bottom: 1px solid white;
  }

  .container li:last-child a {
    border-radius: 0;
  }
}
