* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Figtree", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll;
  min-width: 320px;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: black;
  text-decoration: none;
}

li {
  list-style: none;
}

.borderLine {
  width: 100%;
  height: 1rem;
  background: rgb(7, 52, 95);
  background: linear-gradient(
    90deg,
    rgba(7, 52, 95, 1) 0%,
    rgba(101, 184, 218, 1) 100%
  );
}

.linkBtn {
  padding: 2rem;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  border-radius: 3rem;
  display: inline-block;
  width: auto;
  transition: all 0.2s ease;
  font-weight: bold;
}

.scheduleBtn {
  background-color: var(--dark-blue);
  color: white;
  border: 1px solid var(--dark-blue);
}

.scheduleBtn:hover,
.scheduleBtn:active {
  background-color: transparent;
  color: var(--dark-blue);
}

:root {
  --primary-bg-color: #f4f1e8;
  --secondary-bg-color: #dfdccb;
  --dark-blue: #07345f;
  --sky-blue: #65b8da;
  --light-gray: #ebebeb;
  --max-width: 1920px;
  --serif-font: "Josefin Slab";
}
