.coverImage {
  width: 100%;
  height: 50rem;
  background-image: url("../../public/images/general/dental_office.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation-name: fadein;
  animation-duration: 1s;
}

.formContainer {
  flex-grow: 1;
  padding-right: 2rem;
  position: relative;
  top: -10rem;
}

.imageContainer {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.imageContainer img {
  width: 70%;
  border-radius: 1rem;
  animation-name: slidein;
  animation-duration: 1s;
}

.main {
  display: flex;
  max-width: var(--max-width);
  margin: 0 auto;
}

@media only screen and (max-width: 850px) {
  .formContainer {
    padding-right: 0;
  }

  .imageContainer {
    display: none;
  }

  .main {
    flex-direction: column;
    padding: 2rem;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slidein {
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
