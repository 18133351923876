.banner {
  max-width: var(--max-width);
  margin: 0 auto;
  height: calc(40vh - 9rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner h2 {
  text-align: center;
  font-size: 4rem;
  margin-bottom: 4rem;
}

.banner h3 {
  font-size: 2rem;
}

.bannerList {
  display: flex;
  width: 100%;
}

.bannerList li {
  flex-grow: 1;
  text-align: center;
  padding: 2rem 3rem;
  font-size: 1.8rem;
}

.imageContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 5rem 10rem;
}

.imageContainer img {
  width: 100%;
  max-width: 55rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.linkLine {
  width: 0;
  height: 1rem;
  background-color: var(--sky-blue);
  border-radius: 1rem;
  transition: width 0.5s ease;
}

.services {
  display: flex;
  margin: 0 auto;
  background-color: var(--primary-bg-color);
  padding: 3rem 0;
}

.serviceList {
  font-size: 2rem;
  line-height: 2.5;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
}

.serviceList h2 {
  font-size: 4rem;
  margin-bottom: 2rem;
  color: var(--dark-blue);
}

.serviceList li {
  display: flex;
  align-items: center;
}

.serviceList svg {
  font-size: 2.5rem;
  margin-right: 1.5rem;
}

.servicesMobileHeader {
  display: none;
}

.toServicesLink {
  font-size: 3rem;
  line-height: 1;
  margin-top: 2rem;
  font-family: var(--serif-font);
  color: #ff6a3a;
}

.toServicesLink:hover .linkLine {
  width: 100%;
}

@media only screen and (max-width: 950px) {
  .banner {
    height: auto;
    padding: 4rem 2rem;
  }

  .bannerList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .imageContainer {
    display: none;
  }

  .linkLine {
    display: none;
  }

  .services {
    flex-direction: column;
    background-image: url("../../public/images/general/brush-teeth2.jpg");
    padding: 0;
  }

  .serviceList {
    background-color: rgba(0, 0, 0, 0.4);
    padding: 5rem;
    color: white;
  }

  .serviceList h2 {
    display: none;
  }

  .servicesMobileHeader {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 4rem;
    background-color: var(--primary-bg-color);
    padding: 2rem;
    color: var(--dark-blue);
  }

  .toServicesLink {
    font-weight: bold;
  }
}

@media only screen and (max-width: 600px) {
  .banner h2 {
    font-size: 3rem;
  }

  .bannerList {
    grid-template-columns: 1fr;
  }

  .serviceList {
    padding: 5rem 2rem;
  }

  .servicesMobileHeader {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 420px) {
  .serviceList li span {
    font-size: 1.6rem;
  }
}
