.button {
  background-color: var(--dark-blue);
  color: white;
  padding: 2rem;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  border-radius: 3rem;
  display: inline-block;
  margin-top: 4rem;
}

.container {
  width: 100%;
  margin: 0 auto;
  background-color: white;
  color: var(--dark-blue);
}

.container h2 {
  font-size: 4rem;
  padding-bottom: 1rem;
  font-family: var(--serif-font);
  letter-spacing: 1px;
}

.container hr {
  width: 7rem;
  border: none;
  height: 2px;
  background-color: var(--dark-blue);
  margin-bottom: 2rem;
}

.hours {
  flex-grow: 1;
}

.info {
  flex-grow: 1;
}

.info p {
  margin-bottom: 1rem;
}

.info a:last-child {
  margin-top: 3rem;
}

.infoContainer {
  display: flex;
  padding: 10rem;
  max-width: 1400px;
  margin: 0 auto;
  font-size: 2rem;
}

.link {
  width: fit-content;
  display: flex;
  align-items: center;
  margin-top: 4rem;
  color: var(--dark-blue);
  font-weight: bold;
  transition: all 0.1s linear;
}

.link:hover {
  transform: translateX(1rem);
  color: var(--sky-blue);
}

.link span {
  margin-left: 1rem;
}

.map {
  width: 100%;
  height: 400px;
  border: none;
}

.table {
  display: flex;
}

.table p {
  margin-bottom: 2rem;
}

.days {
  margin-right: 4rem;
}

@media only screen and (max-width: 1400px) {
  .infoContainer {
    padding: 10rem 5rem;
  }
}

@media only screen and (max-width: 800px) {
  .days {
    margin-right: 0;
  }

  .infoContainer {
    flex-direction: column;
  }

  .hours {
    margin-top: 4rem;
  }

  .table {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 430px) {
  .container h2 {
    font-size: 3.2rem;
  }

  .infoContainer {
    padding: 10rem 2rem;
    font-size: 1.7rem;
  }
}
