.footer {
  display: flex;
  justify-content: center;
  background-color: var(--primary-bg-color);
}

.container {
  max-width: var(--max-width);
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 10rem 0;
}

.group a,
.group p {
  font-size: 1.6rem;
  line-height: 1.7;
  color: black;
}

.group h3 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  letter-spacing: 0.2rem;
  color: var(--dark-blue);
  font-weight: 700;
}

.group:first-child li:not(:first-child) {
  border-left: 1px solid var(--sky-blue);
  padding-left: 1rem;
}

.group:nth-child(3) a {
  font-weight: bold;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  width: fit-content;
  transition: all 0.1s linear;
}

.group a:hover {
  transform: translateX(1rem);
  color: var(--sky-blue);
}

.group:nth-child(3) a svg {
  margin-right: 1rem;
}

@media only screen and (max-width: 19200px) {
  .container {
    padding: 10rem 2rem;
  }
}

@media only screen and (max-width: 950px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 5rem;
  }
}

@media only screen and (max-width: 500px) {
  .container {
    grid-template-columns: 1fr;
  }
}
