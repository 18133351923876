.articles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: var(--max-width);
  row-gap: 5rem;
  padding: 5rem 0;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 4rem;
  background-color: var(--primary-bg-color);
}

.section h2 {
  font-size: 4rem;
  color: var(--dark-blue);
}

@media only screen and (max-width: 1920px) {
  .articles {
    padding: 5rem 2rem;
  }
}

@media only screen and (max-width: 1024px) {
  .articles {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 850px) {
  .articles {
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }

  .section h2 {
    font-size: 3.5rem;
    text-align: center;
  }
}

@media only screen and (max-width: 750px) {
  .section h2 {
    font-size: 3rem;
    padding: 0 2rem;
  }
}
