.imageContainer {
  width: 50%;
  display: flex;
  align-items: center;
}

.imageContainer img {
  width: 100%;
}

.info {
  flex-grow: 1;
  padding: 3rem 2rem;
  background-color: var(--secondary-bg-color);
  color: var(--dark-blue);
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info li {
  font-size: 1.8rem;
  line-height: 2;
  list-style: circle;
}

.infoContainer {
  max-width: var(--max-width);
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.title {
  text-align: center;
  padding: 2rem;
  font-size: 2.5rem;
  background-color: var(--primary-bg-color);
  color: var(--dark-blue);
  animation-name: fadein;
  animation-duration: 1s;
}

@media only screen and (max-width: 850px) {
  .infoContainer {
    flex-direction: column;
  }

  .imageContainer {
    width: 100%;
  }
}

@media only screen and (max-width: 460px) {
  .info li {
    font-size: 1.4rem;
  }

  .title {
    font-size: 2rem;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
