.entry {
  display: flex;
  height: 60vh;
  position: relative;
}

.entryImage {
  width: 40%;
  min-width: 50rem;
  background-color: var(--secondary-bg-color);
  display: flex;
  align-items: center;
  position: relative;
}

.entryImage img {
  width: 100%;
  max-width: 76rem;
  position: absolute;
  right: -10rem;
  animation-name: showUp;
  animation-duration: 1s;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.links {
  margin-top: 4rem;
  animation-name: showUp;
  animation-duration: 1s;
}

.text {
  color: var(--dark-blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15rem;
  background-color: var(--primary-bg-color);
  flex-grow: 1;
}

.text a:last-child {
  border: 1px solid var(--sky-blue);
  color: var(--dark-blue);
  margin-left: 2rem;
}

.text a:last-child:hover {
  background-color: var(--sky-blue);
  color: white;
}

.text h1 {
  font-size: 7rem;
  margin: 2rem 0;
  animation-name: showUp;
  animation-duration: 1s;
}

.text p {
  font-size: 2rem;
  font-weight: bold;
}

@media only screen and (max-width: 1270px) {
  .entryImage img {
    right: -5rem;
  }

  .links {
    position: absolute;
    bottom: 5rem;
    right: 5rem;
  }

  .text {
    padding-left: 7rem;
  }

  .text h1 {
    font-size: 5rem;
  }
}

@media only screen and (max-width: 950px) {
  .entry {
    background-image: url("../../public/images/general/home-page-entry.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
  }

  .entryImage {
    display: none;
  }

  .links {
    position: static;
  }

  .text {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    color: white;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .text a:last-child {
    color: white;
    background-color: var(--sky-blue);
  }
}

@media only screen and (max-width: 520px) {
  .text h1 {
    font-size: 5rem;
  }

  .links {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .text a:last-child {
    margin: 3rem 0 0 0;
  }
}

@keyframes showUp {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
