.button {
  border: none;
  padding: 2rem;
  text-transform: uppercase;
  background-color: white;
  color: var(--sky-blue);
  font-weight: bold;
  cursor: pointer;
}

.button:hover {
  background-color: var(--dark-blue);
  color: white;
}

.container {
  width: 100%;
  background-color: var(--sky-blue);
  display: flex;
  flex-direction: column;
  color: white;
  padding: 10rem 5rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 1rem;
  position: relative;
  animation-name: showUp;
  animation-duration: 1s;
  position: relative;
}

.container h2 div:first-child {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.container h2 div:last-child {
  font-size: 2.5rem;
}

.form {
  padding: 4rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3rem;
}

.form input {
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding: 1rem 0;
  color: white;
}

.form input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.form input:focus {
  border-bottom: 1px solid white;
}

.form label {
  text-transform: uppercase;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.form textarea {
  background-color: transparent;
  border: none;
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 1rem;
  color: white;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  padding: 1re 0;
  margin-bottom: 3rem;
}

.inputContainer label span,
.textarea label span {
  color: darkred;
  padding-left: 0.3rem;
}

.loadingCircle {
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;
}
.loadingCircle:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 0.8rem;
  box-sizing: border-box;
  border: 3.2rem solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-hourglass 1.2s infinite;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-bg-color);
  z-index: 99;
  border-radius: 1rem;
  color: var(--dark-blue);
}

.overlay p {
  font-size: 2rem;
  text-align: center;
  margin-top: 3rem;
}

.successCircle {
  width: 8rem;
  height: 8rem;
  background-color: darkgreen;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 4rem;
}

.textarea {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
}

.textarea,
.button {
  grid-column: 1 / span 2;
}

.warning {
  color: darkred;
  padding-top: 1rem;
  font-size: 1.2rem;
}

.warningInput {
  border-bottom: 1px solid darkred !important;
  color: darkred !important;
}

.warningInput::placeholder {
  color: darkred !important;
}

.warningTextArea {
  border: 1px solid darkred !important;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 5rem 2rem;
  }

  .form {
    grid-template-columns: 1fr;
  }

  .textarea,
  .button {
    grid-column: 1 / span 1;
  }
}

@keyframes showUp {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
