.button {
  display: inline-block;
  width: auto;
  color: var(--dark-blue);
  border: 1px solid var(--sky-blue);
  padding: 1.5rem 3rem;
  font-size: 1.6rem;
  margin-top: 2rem;
}

.button:hover {
  background-color: var(--dark-blue);
  color: white;
}

.container {
  transition: all 0.2s linear;
  cursor: pointer;
  padding: 1rem 1rem 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-name: showUp;
  animation-duration: 1s;
}

.container h3 {
  font-size: 2rem;
  margin-top: 1rem;
  text-align: center;
}

.image {
  width: 100%;
}

.container:hover {
  transform: translateY(-1rem);
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@keyframes showUp {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
